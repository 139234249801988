<template>
  <div class="signup-wizard-wizard-menu">
    <template v-if="menuDestination">
      <v-switch v-model="menuDestination.enabled" class="my-0">
        <template slot="label">
          <div class="black--text">
            {{ $t("signup_wizard.show_in_menu") }}
          </div>
        </template>
      </v-switch>
      <v-form v-if="menuDestination.enabled" data-vv-scope="menu">
        <v-layout row wrap>
          <v-flex xs7 class="pr-5">
            <v-layout row wrap>
              <v-flex xs12 class="mb-5">
                <div class="sw-h5">
                  {{ $t("signup_wizard.name_in_menu") }}
                </div>
                <v-text-field
                  v-model="menuDestination.name"
                  v-validate.disable="'required'"
                  data-vv-name="name"
                  :data-vv-as="$t('signup_wizard.name_in_menu')"
                  :placeholder="$t('signup_wizard.menu_name_placeholder')"
                  hide-details
                ></v-text-field>
                <ErrorMessages :error-messages="errors.collect('menu.name')" />
              </v-flex>
              <v-flex xs12 class="mb-5">
                <div class="sw-h5">
                  {{ $t("signup_wizard.menu_description") }}
                </div>
                <v-text-field
                  v-model="menuDestination.description"
                  :placeholder="
                    $t('signup_wizard.menu_description_placeholder')
                  "
                  class="mb-3"
                  hide-details
                ></v-text-field>
                <div class="sw-caption">
                  {{ $t("signup_wizard.menu_description_hint") }}
                </div>
              </v-flex>
              <v-flex xs12 class="mb-5">
                <div class="mb-4 sw-h5">
                  {{ $t("signup_wizard.menu_icon") }}
                </div>
                <IconSelector
                  v-model="menuDestination.icon"
                  :label="$t('signup_wizard.menu_icon_label')"
                  class="mb-3"
                />
              </v-flex>
              <v-flex xs12 class="mb-5 pb-3">
                <div class="mb-4 sw-h5">
                  {{ $t("signup_wizard.signup_answers_body_text") }}
                </div>
                <QuillEditor
                  v-model="plugin.signup_answers_body_text"
                  :config="editorConfig"
                />
              </v-flex>
              <v-flex xs12 class="mb-5 pb-3">
                <div class="mb-4 sw-h5">
                  {{ $t("signup_wizard.signup_answers_fallback_text") }}
                </div>
                <v-textarea
                  v-model="plugin.signup_answers_fallback_text"
                  :placeholder="
                    $t('signup_wizard.signup_answers_fallback_text_placeholder')
                  "
                  no-resize
                  hide-details
                />
              </v-flex>
              <v-flex xs12>
                <div class="mb-4 sw-h5">
                  {{ $t("app_page_wizard.promoted_menu_item") }}
                </div>
              </v-flex>
              <v-flex xs12 mb-4>
                <v-switch v-model="menuDestination.promoted" class="my-0">
                  <template slot="label">
                    <div class="black--text">
                      {{
                        menuDestination.promoted
                          ? $t("common.yes")
                          : $t("common.no")
                      }}
                    </div>
                  </template>
                </v-switch>
              </v-flex>
              <v-flex xs12>
                <div class="mb-4 sw-h5">
                  {{ $t("signup_wizard.visibility") }}
                </div>
              </v-flex>
              <v-flex xs12 class="mb-5">
                <v-radio-group
                  v-model="menuDestination.is_public"
                  row
                  class="ma-0"
                  hide-details
                >
                  <v-layout row wrap>
                    <v-flex xs4 class="pr-4">
                      <v-radio :value="null">
                        <template slot="label">
                          <div class="black--text">
                            {{ $t("signup_wizard.visibility_all") }}
                          </div>
                        </template>
                      </v-radio>
                      <div class="sw-caption pt-3 pl-4 ml-2">
                        {{ $t("signup_wizard.visibility_all_hint") }}
                      </div>
                    </v-flex>
                    <v-flex xs4 class="px-4">
                      <v-radio :value="false">
                        <template slot="label">
                          <div class="black--text">
                            {{ $t("signup_wizard.visibility_authorized") }}
                          </div>
                        </template>
                      </v-radio>
                      <div class="sw-caption pt-3 pl-4 ml-2">
                        {{ $t("signup_wizard.visibility_authorized_hint") }}
                      </div>
                    </v-flex>
                    <v-flex xs4 class="pl-4">
                      <v-radio :value="true">
                        <template slot="label">
                          <div class="black--text">
                            {{ $t("signup_wizard.visibility_unauthorized") }}
                          </div>
                        </template>
                      </v-radio>
                      <div class="sw-caption pt-3 pl-4 ml-2">
                        {{ $t("signup_wizard.visibility_unauthorized_hint") }}
                      </div>
                    </v-flex>
                  </v-layout>
                </v-radio-group>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs5 class="pl-5">
            <v-layout row wrap>
              <v-flex xs8>
                <tooltip name="PagesPlaceInMenu">
                  <div class="mb-4 sw-h5">
                    {{ $t("signup_wizard.place_page_in_menu") }}
                  </div>
                </tooltip>
                <div class="menu-draggable px-4">
                  <vue-draggable
                    v-model="menu.destinations"
                    :handle="'.draggable-handle'"
                  >
                    <div
                      v-for="(destination, i) in menu.destinations"
                      :key="`destination-${i}`"
                      id="vue-draggable"
                      class="menu-items"
                    >
                      <v-layout row class="py-4">
                        <v-flex xs2 class="text-center">
                          <font-awesome-icon
                            v-if="destination.editable"
                            icon="grip-vertical"
                            class="draggable-handle sw-accent"
                          ></font-awesome-icon>
                        </v-flex>
                        <v-flex xs2 class="text-center">
                          <font-awesome-icon
                            v-if="destination.icon"
                            :icon="transformIcon(destination.icon)"
                            class="sw-accent"
                          ></font-awesome-icon>
                        </v-flex>
                        <v-flex xs8>
                          <div class="sw-body-1 name">
                            {{
                              destination.name ||
                              $t("signup_wizard.new_page_name")
                            }}
                          </div>
                        </v-flex>
                      </v-layout>
                    </div>
                  </vue-draggable>
                </div>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-form>
    </template>
  </div>
</template>

<script>
import draggable from "vuedraggable";

export default {
  inject: ["parentValidator"],
  computed: {
    plugin: {
      get() {
        return this.$store.getters.signupWizardPlugin;
      },
      set(val) {
        this.$store.dispatch("setSignupWizardPlugin", val);
      },
    },
    menu: {
      get() {
        return this.$store.getters.signupWizardIonicAppSidemenu;
      },
      set(val) {
        this.$store.dispatch("setSignupWizardIonicAppSidmenu", val);
      },
    },
    menuDestination() {
      if (!this.menu || !this.menu.destinations) {
        return;
      }
      return this.menu.destinations.find((el) => el.editable);
    },
    editorConfig() {
      return {
        label: "",
        placeholder: "Write text here",
        style: {
          height: "150px",
        },
      };
    },
  },
  components: {
    "vue-draggable": draggable,
  },
  created() {
    this.$validator = this.parentValidator;
  },
  methods: {
    transformIcon(icon) {
      return icon ? icon.split(" ") : [];
    },
  },
};
</script>

<style lang="scss">
.signup-wizard-wizard-menu {
  .menu-draggable {
    border: 1px solid $accent;
  }

  .menu-items:not(:last-child) {
    border-bottom: 1px solid $accent;
  }

  .name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .v-input--radio-group .v-input__control {
    width: 100%;
  }
}
</style>
