<template>
  <v-dialog v-model="dialog" width="1200" persistent>
    <v-card class="border-radius">
      <v-card-actions>
        <v-spacer />
        <v-btn icon @click="dialog = false">
          <v-icon small>close</v-icon>
        </v-btn>
      </v-card-actions>
      <v-layout v-if="attribute.id" class="row wrap">
        <v-flex v-if="viewMode === 1" class="xs12">
          <SimpleRelations
            :groupId="groupId"
            :groupPluginId="groupPluginId"
            :categoryId="categoryId"
            :attributeId="attribute.id"
            v-on="$listeners"
            @change-view-mode="changeViewMode"
            @close-dialog="close"
          />
        </v-flex>
        <v-flex v-if="viewMode === 2" class="xs12">
          <AdvancedRelations
            :groupId="groupId"
            :groupPluginId="groupPluginId"
            :categoryId="categoryId"
            :attributeId="attribute.id"
            v-on="$listeners"
            @change-view-mode="changeViewMode"
            @close-dialog="close"
          />
        </v-flex>
      </v-layout>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from "lodash";
import SimpleRelations from "@/components/SignupWizard/SignupQuestions/Relations/SimpleRelations.vue";
import AdvancedRelations from "@/components/SignupWizard/SignupQuestions/Relations/AdvancedRelations.vue";

export default {
  props: {
    groupId: {
      type: [String, Number],
      required: true,
    },
    groupPluginId: {
      type: [String, Number],
      required: true,
    },
    categoryId: {
      type: [String, Number],
      required: true,
    },
  },
  data: () => ({
    isLoading: false,
    dialog: false,
    viewMode: null,
    attribute: {},
  }),
  components: {
    SimpleRelations,
    AdvancedRelations,
  },
  methods: {
    open(attribute) {
      if (!attribute) return;

      this.getGroupUserAttributeRelations(attribute);

      this.attribute = attribute;
      this.viewMode = null;
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.attribute = {};
    },
    changeViewMode(mode) {
      if (mode) {
        this.viewMode = mode;
      }
    },
    getGroupUserAttributeRelations(attribute) {
      if (!attribute) return;

      const params = [this.groupId, attribute.id];

      this.isLoading = true;

      this.$api.groupUserAttributeRelations
        .get(...params)
        .then((response) => {
          this.isLoading = false;

          if (response.status === 200 && response.data.data) {
            const requirements = response.data.data.requirements;

            if (!_.isEmpty(requirements)) {
              const reqValues = Object.values(requirements);

              if (reqValues && reqValues[0]) {
                const isEqual = reqValues.every((val, i, arr) =>
                  _.isEqual(val, arr[0]),
                );

                if (!isEqual || reqValues[0].mode === "any") {
                  return this.changeViewMode(2);
                }
              }
            }

            this.changeViewMode(1);

          }
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style scoped></style>
