<template>
  <div class="signup-settings">
    <v-form data-vv-scope="settings">
      <v-layout>
        <v-flex xs6>
          <v-layout row wrap class="mb-5 pr-5">
            <v-flex xs12 class="mb-2 sw-h5">{{
              $t("signup_wizard.name")
            }}</v-flex>
            <v-flex xs12>
              <tooltip slot="label" name="SignupName">
                <label class="v-label grey--text">{{
                  $t("signup_wizard.name_description")
                }}</label>
              </tooltip>
            </v-flex>
            <v-flex xs12>
              <v-text-field
                v-model="plugin.name"
                v-validate.disable="'required|min:3'"
                data-vv-name="name"
                :data-vv-as="$t('signup_wizard.name_data_as')"
                :placeholder="$t('signup_wizard.name_placeholder')"
                hide-details
              ></v-text-field>
              <ErrorMessages
                :error-messages="errors.collect('settings.name')"
              />
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>

      <v-layout>
        <v-flex xs6>
          <v-layout row wrap class="mb-5 pr-5">
            <v-flex xs12 class="sw-h5">{{
              $t("signup_wizard.headline")
            }}</v-flex>
            <v-flex xs12>
              <v-text-field
                v-model="plugin.signup_headline"
                v-validate.disable="'max:255'"
                data-vv-name="signup_headline"
                :data-vv-as="$t('signup_wizard.headline_data_as')"
                :placeholder="$t('signup_wizard.headline_placeholder')"
                counter="255"
                hide-details
              />
              <ErrorMessages
                :error-messages="errors.collect('settings.signup_headline')"
              />
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>

      <v-layout>
        <v-flex xs6>
          <v-layout row wrap class="mb-5 pr-5">
            <v-flex xs12 class="sw-h5 mb-4">{{
              $t("signup_wizard.intro_content")
            }}</v-flex>
            <v-flex xs12>
              <QuillEditor
                v-model="plugin.signup_intro_content"
                :config="editorConfig"
              />
              <ErrorMessages
                :error-messages="
                  errors.collect('settings.signup_intro_content')
                "
              />
            </v-flex>
          </v-layout>
          <v-layout row wrap class="mb-5 pr-5">
            <v-flex xs12 class="sw-h5">{{
              $t("signup_wizard.start_registration_button")
            }}</v-flex>
            <v-flex xs12>
              <v-text-field
                v-model="plugin.signup_start_registration_button"
                data-vv-name="signup_start_registration_button"
                :data-vv-as="
                  $t('signup_wizard.start_registration_button_data_as')
                "
                :placeholder="
                  $t('signup_wizard.start_registration_button_placeholder')
                "
                hide-details
              />
              <ErrorMessages
                :error-messages="
                  errors.collect('settings.signup_start_registration_button')
                "
              />
            </v-flex>
          </v-layout>
          <v-layout row wrap class="mb-5 pr-5">
            <v-flex xs12 class="sw-h5 mb-4">{{
              $t("signup_wizard.active_from")
            }}</v-flex>
            <v-flex xs12 class="mb-3">
              <date-picker
                v-model="plugin.signup_active_from"
                width="100%"
                :lang="datePickerLang"
                type="datetime"
                :first-day-of-week="1"
                :format="'MMMM DD, YYYY H:mm'"
                :minute-step="5"
                v-validate.disable="'required'"
                name="signup_active_from"
                :data-vv-as="$tc('from', 2)"
                :placeholder="$t('signup_wizard.active_from_placeholder')"
                :editable="false"
                @input="updateActiveTill"
                append-to-body
              />
              <ErrorMessages
                v-if="
                  errors && errors.collect('settings.signup_active_from').length
                "
                :error-messages="errors.collect('settings.signup_active_from')"
              />
            </v-flex>
            <v-flex xs12 class="mb-2">
              <date-picker
                v-model="plugin.signup_active_till"
                width="100%"
                :lang="datePickerLang"
                type="datetime"
                :format="'MMMM DD, YYYY H:mm'"
                :minute-step="5"
                v-validate.disable="'required'"
                :not-before="plugin.signup_active_from"
                name="signup_active_till"
                :data-vv-as="$tc('to', 2)"
                :placeholder="$t('signup_wizard.active_till_placeholder')"
                :editable="false"
                append-to-body
              />
              <ErrorMessages
                v-if="
                  errors && errors.collect('settings.signup_active_till').length
                "
                :error-messages="errors.collect('settings.signup_active_till')"
              />
            </v-flex>
            <v-flex xs12 class="px-2 grey--text caption"
              >{{ $t("current-timezone") }}: {{ userTimezone }}</v-flex
            >
          </v-layout>
          <v-layout row wrap class="mb-5">
            <v-flex xs12 class="mb-4 sw-h5">{{
              $t("signup_wizard.settings")
            }}</v-flex>
            <v-flex xs12 class="mb-4">
              <v-switch
                v-model="plugin.signup_verification_enabled"
                class="ma-0"
                hide-details
              >
                <template slot="label">
                  <div class="caption black--text">
                    {{ $t("signup_wizard.verification_enabled_label") }}
                  </div>
                </template>
              </v-switch>
            </v-flex>
            <v-flex xs12 class="mb-5">
              <v-switch
                v-model="plugin.signup_phone_required"
                class="ma-0"
                hide-details
              >
                <template slot="label">
                  <div class="caption black--text">
                    {{ $t("signup_wizard.phone_required_label") }}
                  </div>
                </template>
              </v-switch>
            </v-flex>
            <v-flex xs12>
              <v-layout row wrap class="pb-4">
                <v-flex xs12 class="mb-2">{{
                  $t("signup_wizard.signup_email_domain")
                }}</v-flex>
                <v-flex xs6 class="pr-5">
                  <v-text-field
                    v-model="plugin.signup_email_domain"
                    v-validate.disable="{
                      regex:
                        /^((?=[a-z0-9-]{1,63}\.)[a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,63}$/,
                    }"
                    data-vv-name="signup_email_domain"
                    :data-vv-as="
                      $t('signup_wizard.signup_email_domain_invalid')
                    "
                    hide-details
                    :label="$t('signup_wizard.signup_email_domain_placeholder')"
                  />
                  <ErrorMessages
                    :error-messages="
                      errors.collect('settings.signup_email_domain')
                    "
                  />
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs6>
          <v-layout row wrap class="mb-5 pl-5">
            <v-flex xs12 class="sw-h5 mb-4">{{
              $t("signup_wizard.thank_you_content")
            }}</v-flex>
            <v-flex xs12>
              <QuillEditor
                v-model="plugin.signup_thank_you_content"
                :config="editorConfig"
              />
              <ErrorMessages
                :error-messages="
                  errors.collect('settings.signup_thank_you_content')
                "
              />
            </v-flex>
          </v-layout>
          <v-layout row wrap class="mb-5 pl-5">
            <v-flex xs12 class="sw-h5 mb-4">{{
              $t("signup_wizard.signup_closed_content")
            }}</v-flex>
            <v-flex xs12>
              <QuillEditor
                v-model="plugin.signup_closed_content"
                :config="editorConfig"
              />
              <ErrorMessages
                :error-messages="
                  errors.collect('settings.signup_closed_content')
                "
              />
            </v-flex>
          </v-layout>
          <v-layout row wrap class="pl-5">
            <v-flex xs12 class="mb-4">
              <v-switch
                v-model="plugin.signup_required_privacy_disclaimer"
                class="ma-0"
                hide-details
              >
                <template slot="label">
                  <div class="caption black--text">
                    {{
                      $t("signup_wizard.force_to_user_for_privacy_disclaimer")
                    }}
                  </div>
                </template>
              </v-switch>
            </v-flex>
            <v-flex xs12>
              <QuillEditor
                v-model="plugin.signup_privacy_disclaimer"
                :config="editorConfig"
              />
              <ErrorMessages
                :error-messages="
                  errors.collect('settings.signup_privacy_disclaimer')
                "
              />
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-form>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import moment from "moment-timezone";

export default {
  inject: ["parentValidator"],
  computed: {
    plugin: {
      get() {
        return this.$store.getters.signupWizardPlugin;
      },
      set(val) {
        this.$store.dispatch("setSignupWizardPlugin", val);
      },
    },
    editorConfig() {
      return {
        label: "",
        placeholder: "Write text here",
        style: {
          height: "150px",
        },
      };
    },
  },
  components: {
    DatePicker,
  },
  created() {
    this.$validator = this.parentValidator;
  },
  methods: {
    updateActiveTill() {
      if (!this.plugin.signup_active_till) return;

      const minTill = moment(this.plugin.signup_active_from)
        .add(24, "hours")
        .toISOString();

      const tillIsSameOrBeforeMinTill = moment(
        this.plugin.signup_active_till,
      ).isSameOrBefore(minTill);

      if (
        tillIsSameOrBeforeMinTill ||
        this.plugin.signup_active_till === null
      ) {
        this.plugin.signup_active_till = minTill;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
