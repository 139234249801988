<template>
  <div class="signup-wizard-confirmation pb-5">
    <div class="mb-4 sw-h3 sw-accent">{{ $t("signup_wizard.settings") }}</div>
    <v-layout>
      <v-flex xs6>
        <v-layout row wrap class="mb-5 pr-5">
          <v-flex xs12 class="mb-4 sw-h5">
            {{ $t("article_wizard.name") }}
          </v-flex>
          <v-flex xs12>{{ plugin.name }}</v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-layout>
      <v-flex xs6>
        <v-layout row wrap class="mb-5 pr-5">
          <v-flex xs12 class="mb-4 sw-h5">{{
            $t("signup_wizard.headline")
          }}</v-flex>
          <v-flex xs12>{{ plugin.signup_headline }}</v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-layout>
      <v-flex xs6>
        <v-layout row wrap class="mb-5 pr-5">
          <v-flex xs12 class="mb-4 sw-h5">{{
            $t("signup_wizard.intro_content")
          }}</v-flex>
          <v-flex xs12 class="pb-4">
            <div v-html="nl2br(plugin.signup_intro_content)"></div>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs6>
        <v-layout row wrap class="mb-5 pl-5">
          <v-flex xs12 class="mb-4 sw-h5">{{
            $t("signup_wizard.thank_you_content")
          }}</v-flex>
          <v-flex xs12 class="pb-4">
            <div v-html="nl2br(plugin.signup_thank_you_content)"></div>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>

    <div class="mb-4 sw-h3 sw-accent">{{ $t("signup_wizard.questions") }}</div>

    <v-layout v-if="plugin.signup_guests_attribute_tags" row wrap class="mb-5">
      <v-flex xs12>
        <v-layout
          v-for="(tag, tagIndex) in attributeTags"
          :key="`tag-${tagIndex}`"
          class="row wrap mb-4"
        >
          <v-flex
            v-if="!tag.hide_name || !tag.hide_description"
            xs12
            class="mb-4"
          >
            <v-layout class="row wrap">
              <v-flex v-show="!tag.hide_name" xs12 class="sw-h5">{{
                tag.name
              }}</v-flex>
              <v-flex v-show="!tag.hide_description" xs12>{{
                tag.description
              }}</v-flex>
            </v-layout>
          </v-flex>
          <v-flex v-if="attributesFilteredBytag(tag.id)" xs12>
            <v-expansion-panel>
              <template
                v-for="(attribute, i) in attributesFilteredBytag(tag.id)"
              >
                <v-expansion-panel-content
                  v-if="!attribute.hidden"
                  :key="'attribute-' + i"
                >
                  <template slot="header">
                    <v-layout class="row no-wrap align-items-center">
                      <v-flex xs12 class="py-1">
                        <span class="sw-h5 font-weight-light">
                          {{ attribute.name }}
                        </span>
                      </v-flex>
                    </v-layout>
                  </template>
                  <v-card color="grey lighten-5">
                    <v-card-text>
                      <template
                        v-if="
                          attribute.details && attribute.details.description
                        "
                      >
                        <div
                          v-html="attribute.details.description"
                          class="sw-content-typography pt-2 pb-4 px-2"
                        ></div>
                      </template>
                      <div
                        v-if="
                          attribute.type === 'options' &&
                          attribute.is_collection
                        "
                        class="px-2 py-3"
                      >
                        <div
                          v-for="(option, i) in attribute.options"
                          :key="`option-${i}`"
                        >
                          <v-checkbox
                            :label="option.name"
                            hide-details
                            class="shrink mt-0"
                            disabled
                          />
                        </div>
                        <div v-if="!attribute || !attribute.options[0]">
                          {{ $t("no-options-added-yet") }}!
                        </div>
                      </div>
                      <div
                        v-if="
                          attribute.type === 'options' &&
                          !attribute.is_collection
                        "
                        class="px-2"
                      >
                        <v-radio-group column>
                          <template v-for="(option, i) in attribute.options">
                            <v-radio
                              :key="`option-${i}`"
                              :label="option.name"
                              hide-details
                              disabled
                            />
                          </template>
                        </v-radio-group>
                        <div v-if="!attribute.options.length">
                          {{ $t("no-options-added-yet") }}!
                        </div>
                      </div>
                      <div v-if="attribute.type === 'varchar'" class="px-2">
                        <v-text-field
                          :placeholder="$t('textFieldPlaceholder')"
                          hide-details
                          solo
                          disabled
                        />
                      </div>
                      <div v-if="attribute.type === 'text'" class="px-2">
                        <v-textarea
                          :placeholder="$t('textFieldPlaceholder')"
                          no-resize
                          hide-details
                          solo
                          disabled
                        />
                      </div>
                      <div v-if="attribute.type === 'datetime'" class="px-2">
                        <v-text-field
                          :placeholder="'DD.MM.YYYY'"
                          hide-details
                          solo
                          disabled
                        />
                      </div>
                    </v-card-text>
                  </v-card>
                </v-expansion-panel-content>
              </template>
            </v-expansion-panel>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>

    <v-layout
      v-if="!plugin.signup_guests_attribute_tags"
      row
      wrap
      class="mb-5 pb-4"
    >
      <v-flex v-if="attributes && attributes[0]" xs12>
        <v-expansion-panel>
          <template v-for="(attribute, i) in attributes">
            <v-expansion-panel-content
              v-if="!attribute.hidden"
              :key="'attribute-' + i"
            >
              <template slot="header">
                <v-layout class="row no-wrap align-items-center">
                  <v-flex xs12 class="py-1">
                    <span class="sw-h5 font-weight-light">
                      {{ attribute.name }}
                    </span>
                  </v-flex>
                </v-layout>
              </template>
              <v-card color="grey lighten-5">
                <v-card-text>
                  <template
                    v-if="attribute.details && attribute.details.description"
                  >
                    <div
                      v-html="attribute.details.description"
                      class="sw-content-typography pt-2 pb-4 px-2"
                    ></div>
                  </template>
                  <div
                    v-if="
                      attribute.type === 'options' && attribute.is_collection
                    "
                    class="px-2 py-3"
                  >
                    <div
                      v-for="(option, i) in attribute.options"
                      :key="`option-${i}`"
                    >
                      <v-checkbox
                        :label="option.name"
                        hide-details
                        class="shrink mt-0"
                        disabled
                      />
                    </div>
                    <div v-if="!attribute || !attribute.options[0]">
                      {{ $t("no-options-added-yet") }}!
                    </div>
                  </div>
                  <div
                    v-if="
                      attribute.type === 'options' && !attribute.is_collection
                    "
                    class="px-2"
                  >
                    <v-radio-group column>
                      <template v-for="(option, i) in attribute.options">
                        <v-radio
                          :key="`option-${i}`"
                          :label="option.name"
                          hide-details
                          disabled
                        />
                      </template>
                    </v-radio-group>
                    <div v-if="!attribute.options.length">
                      {{ $t("no-options-added-yet") }}!
                    </div>
                  </div>
                  <div v-if="attribute.type === 'varchar'" class="px-2">
                    <v-text-field
                      :placeholder="$t('textFieldPlaceholder')"
                      hide-details
                      solo
                      disabled
                    />
                  </div>
                  <div v-if="attribute.type === 'text'" class="px-2">
                    <v-textarea
                      :placeholder="$t('textFieldPlaceholder')"
                      no-resize
                      hide-details
                      solo
                      disabled
                    />
                  </div>
                  <div v-if="attribute.type === 'datetime'" class="px-2">
                    <v-text-field
                      :placeholder="'DD.MM.YYYY'"
                      hide-details
                      solo
                      disabled
                    />
                  </div>
                </v-card-text>
              </v-card>
            </v-expansion-panel-content>
          </template>
        </v-expansion-panel>
      </v-flex>
    </v-layout>

    <template v-if="messageTemplate.id && messageTemplate.active">
      <div class="mb-4 sw-h3 sw-accent">Email confirmation</div>
      <v-layout class="mb-5 pb-4">
        <v-flex xs6>
          <v-layout row wrap class="mb-5 pr-5">
            <v-flex xs12 class="mb-4 sw-h5"> Subject </v-flex>
            <v-flex xs12>{{ messageTemplate.subject }}</v-flex>
          </v-layout>
          <v-layout row wrap class="mb-5 pr-5">
            <v-flex xs12 class="mb-4 sw-h5"> Message body </v-flex>
            <v-flex xs12>
              <div
                v-html="unparseContent(messageTemplate.body)"
                class="sw-content-typography message-template-body"
              ></div>
            </v-flex>
          </v-layout>
          <v-layout>
            <v-flex xs12>
              <v-layout row wrap class="pr-5">
                <v-flex xs12 class="mb-1 sw-h5"> Send test message </v-flex>
                <v-flex v-if="messageTemplate && messageTemplate.id" xs12>
                  <TestMessages
                    :group-id="groupId"
                    :message-template-id="messageTemplate.id"
                    :success-message="[
                      'signup_wizard.test_message_success',
                      'lang',
                    ]"
                  />
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex v-if="messageTemplate.message_image" xs4>
          <v-layout>
            <v-flex xs12 class="pl-5">
              <v-card flat class="sw-primary-bg">
                <v-card-text class="py-5 text-center">
                  <img
                    v-if="
                      messageTemplate.message_image.presets &&
                      messageTemplate.message_image.presets.thumb
                    "
                    :src="messageTemplate.message_image.presets.thumb"
                  />
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </template>

    <template v-if="appMenuDestination && appMenuDestination.enabled">
      <div class="mb-4 sw-h3 sw-accent">
        {{ $t("signup_wizard.menu_step_title") }}
      </div>

      <v-layout>
        <v-flex xs8 class="pr-5">
          <v-layout row wrap>
            <v-flex xs12 class="mb-5">
              <div class="mb-4 sw-h5">
                {{ $t("signup_wizard.name_in_menu") }}
              </div>
              {{ appMenuDestination.name }}
            </v-flex>
          </v-layout>
          <v-layout row wrap class="mb-5 pb-4">
            <v-flex xs12 class="mb-4 sw-h5">
              {{ $t("signup_wizard.signup_answers_body_text") }}
            </v-flex>
            <v-flex xs12 class="mb-5">
              <div v-html="plugin.signup_answers_body_text"></div>
            </v-flex>
            <v-flex xs12 class="mb-4 sw-h5">
              {{ $t("signup_wizard.signup_answers_fallback_text") }}
            </v-flex>
            <v-flex xs12>
              <div v-html="nl2br(plugin.signup_answers_fallback_text)"></div>
            </v-flex>
          </v-layout>
          <v-layout class="row wrap">
            <v-flex xs6>
              <v-layout row wrap>
                <v-flex xs12>
                  <div class="mb-4 sw-h5">
                    {{ $t("signup_wizard.visibility") }}
                  </div>
                </v-flex>
                <v-flex xs12>
                  <v-radio-group
                    v-model="radioGroup"
                    row
                    class="ma-0"
                    hide-details
                  >
                    <v-layout row wrap>
                      <v-flex class="pr-4">
                        <v-radio :value="1" disabled>
                          <template slot="label">
                            <div class="black--text">
                              {{ isPublicRadioLabel }}
                            </div>
                          </template>
                        </v-radio>
                        <div class="caption pt-3 pl-4 ml-2">
                          {{ isPublicRadioText }}
                        </div>
                      </v-flex>
                    </v-layout>
                  </v-radio-group>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs6 class="pl-5">
              <v-layout row wrap>
                <v-flex xs12>
                  <div class="mb-4 sw-h5">
                    {{ $t("signup_wizard.promoted_menu_item") }}
                  </div>
                </v-flex>
                <v-flex xs12>
                  <v-radio-group
                    v-model="appMenuDestination.promoted"
                    row
                    class="ma-0"
                    hide-details
                  >
                    <v-layout row wrap>
                      <v-flex class="pr-4">
                        <v-radio :value="true" disabled>
                          <template slot="label">
                            <div class="black--text">
                              {{
                                appMenuDestination.promoted
                                  ? $t("common.yes")
                                  : $t("common.no")
                              }}
                            </div>
                          </template>
                        </v-radio>
                      </v-flex>
                    </v-layout>
                  </v-radio-group>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs4 class="pl-5">
          <v-layout row wrap>
            <v-flex xs12>
              <div class="mb-4 sw-h5">
                {{ $t("signup_wizard.place_in_menu") }}
              </div>
              <div class="menu-draggable px-4">
                <div
                  v-for="(destination, i) in ionicAppSidemenu.destinations"
                  :key="`destination-${i}`"
                  :class="['menu-items', { active: destination.editable }]"
                >
                  <v-layout row class="py-4">
                    <v-flex xs2 class="text-center">
                      <font-awesome-icon
                        v-if="destination.icon"
                        :icon="transformIcon(destination.icon)"
                        class="sw-accent"
                      ></font-awesome-icon>
                    </v-flex>
                    <v-flex xs10>
                      <div class="name sw-body-1">{{ destination.name }}</div>
                    </v-flex>
                  </v-layout>
                </div>
              </div>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </template>
  </div>
</template>

<script>
import moment from "moment-timezone";
import TestMessages from "@/components/TestMessages.vue";

export default {
  props: {
    groupId: {
      type: [String, Number],
    },
  },
  data: () => ({
    radioGroup: 1,
  }),
  computed: {
    plugin() {
      return this.$store.getters.signupWizardPlugin;
    },
    attributes() {
      return this.$store.getters.signupWizardAttributes;
    },
    attributeTags() {
      return this.$store.getters.signupWizardAttributeTags;
    },
    messageTemplate() {
      return this.$store.getters.signupWizardMessageTemplate;
    },
    ionicAppSidemenu() {
      return this.$store.getters.signupWizardIonicAppSidemenu;
    },
    appMenuDestination() {
      if (!this.ionicAppSidemenu || !this.ionicAppSidemenu.destinations) {
        return;
      }
      return this.ionicAppSidemenu.destinations.find((el) => el.editable);
    },
    isPublicRadioLabel() {
      switch (this.appMenuDestination.is_public) {
        case false:
          return this.$t("signup_wizard.visibility_authorized");
        case true:
          return this.$t("signup_wizard.visibility_unauthorized");
        default:
          return this.$t("signup_wizard.visibility_all");
      }
    },
    isPublicRadioText() {
      switch (this.appMenuDestination.is_public) {
        case false:
          return this.$t("signup_wizard.visibility_authorized_hint");
        case true:
          return this.$t("signup_wizard.visibility_unauthorized_hint");
        default:
          return this.$t("signup_wizard.visibility_all_hint");
      }
    },
  },
  components: {
    TestMessages,
  },
  methods: {
    attributesFilteredBytag(tagId) {
      return this.attributes.filter((attribute) =>
        attribute.tags.some((el) => el.id === tagId),
      );
    },
    showDate(date) {
      return moment.tz(date, this.userTimezone).format("YYYY-MM-DD");
    },
    showTime(date) {
      return moment.tz(date, this.userTimezone).format("HH:mm");
    },
    isNow() {
      const now = moment.tz(this.userTimezone).toISOString();

      const start = moment
        .tz(this.messageTemplate.send_time_start, this.userTimezone)
        .toISOString();

      const end = moment
        .tz(this.messageTemplate.send_time_end, this.userTimezone)
        .toISOString();

      const startIsSameOrBeforeNow = moment(start).isSameOrBefore(now);
      const nowIsSameOrBeforeEnd = moment(now).isSameOrBefore(end);

      if (startIsSameOrBeforeNow && nowIsSameOrBeforeEnd) {
        return {
          date: this.$t("now"),
          time: null,
        };
      }
      return {
        date: this.showDate(start),
        time: this.showTime(start),
      };
    },
    transformIcon(icon) {
      return icon ? icon.split(" ") : [];
    },
  },
};
</script>

<style lang="scss">
.signup-wizard-confirmation {
  .message-template-body {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    li {
      color: #1f363d;
    }

    a {
      text-decoration: underline !important;
      color: #3869d4 !important;
    }
  }

  .menu-draggable {
    border: 1px solid $accent;

    .active {
      background: #f3f2f2;
    }
  }

  .menu-items:not(:last-child) {
    border-bottom: 1px solid $accent;
  }

  .name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .v-input--radio-group .v-input__control {
    width: 100%;
  }
}
</style>
