<template>
  <div class="signup-message">
    <tooltip name="SignupEmailConfirmation">
      <v-switch
        v-model="messageTemplate.active"
        :true-value="1"
        :false-value="0"
        class="my-0"
      >
        <template slot="label">
          <div class="black--text">
            {{ $t("signup_wizard.send_email_confirmation") }}
          </div>
        </template>
      </v-switch>
    </tooltip>
    <div v-show="messageTemplate.active">
      <v-form data-vv-scope="message-template">
        <v-layout>
          <v-flex xs8>
            <v-layout row wrap class="mb-5 pr-5">
              <v-flex xs12 class="sw-h5">
                <tooltip name="SignupWizardEmailSubjectInfoText">{{
                  $tc("subject", 1)
                }}</tooltip>
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  type="text"
                  v-model="messageTemplate.subject"
                  v-validate.disable="'required|min:3'"
                  :data-vv-name="'subject'"
                  :data-vv-as="$tc('subject', 2)"
                  :placeholder="$t('signup_wizard.message_subject_placeholder')"
                  hide-details
                />
                <ErrorMessages
                  :error-messages="errors.collect('message-template.subject')"
                />
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-layout row wrap class="mb-5">
          <v-flex xs12 class="mb-4 sw-h5">
            <tooltip name="SignupWizardEmailBodyInfoText">{{
              this.$t("messageTemplateBodyLabel")
            }}</tooltip>
          </v-flex>
          <v-flex xs12>
            <QuillEditor
              :value="messageTemplate.body"
              @input="messageTemplate.body = $event"
              :config="editorConfig"
              :show-login-link="true"
            />
            <ErrorMessages
              :error-messages="errors.collect('message-template.body')"
            />
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex xs4>
            <v-card flat class="mb-4 sw-primary-bg">
              <v-card-text class="py-5 text-center">
                <MediaManager
                  v-model="messageTemplate.message_image"
                  :media-type="'images'"
                  :label="$t('signup_wizard.message_image_label')"
                />
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </v-form>
    </div>
  </div>
</template>

<script>
export default {
  inject: ["parentValidator"],
  props: {
    groupId: {
      type: [String, Number],
    },
  },
  computed: {
    editorConfig() {
      return {
        label: "",
        shortcodes: true,
        style: {
          height: "400px",
          "--primary-color": "#1f363d",
          "--secondary-color": "#3869D4",
        },
      };
    },
    messageTemplate: {
      get() {
        return this.$store.getters.signupWizardMessageTemplate;
      },
      set(val) {
        this.$store.dispatch("setSignupWizardMessageTemplate", val);
      },
    },
  },
  created() {
    this.$validator = this.parentValidator;
  },
};
</script>

<style lang="scss" scoped></style>
