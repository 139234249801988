<template>
  <v-card-text v-if="!loading" class="pt-0">
    <v-layout class="row wrap text-center">
      <v-flex v-if="attribute && attribute.name" class="xs12 mb-3 sw-h5">
        {{
          $t("when-will-question-be-shown", {
            question: `"${attribute.name}"`,
          })
        }}
      </v-flex>
      <v-flex class="xs12 mb-3">
        {{ $t("when-the-following-answers-is-chosen") }}
      </v-flex>
      <v-flex
        v-for="(item, i) in selectedAttributes"
        :key="`selected-attribute-${i}`"
        class="xs12 mb-4"
      >
        <v-card class="pa-4">
          <v-layout class="row nowrap align-items-center">
            <v-flex class="xs12">
              <v-layout class="row wrap">
                <v-flex class="xs12">
                  <v-select
                    v-model="selectedAttributes[i]"
                    :items="filterAvalableAttributes(selectedAttributes[i])"
                    item-text="name"
                    item-value="id"
                    return-object
                    v-validate.disable="'required'"
                    :data-vv-name="`name_${i}`"
                    :data-vv-as="$tc('question', 2)"
                    :error-messages="errors.collect(`name_${i}`)"
                    :label="$t('choose-question')"
                  >
                    <template slot="no-data">
                      <div class="px-4 py-3">{{ $t("no-data") }}</div>
                    </template>
                    <template slot="item" slot-scope="data">
                      <div class="slot-item overflow-hidden">
                        {{ data.item.name }}
                        <div
                          v-if="data.item.description"
                          class="caption grey--text"
                        >
                          {{ data.item.description }}
                        </div>
                      </div>
                    </template>
                  </v-select>
                </v-flex>
                <v-flex
                  v-if="selectedAttributes[i] && selectedAttributes[i].options"
                  class="xs12"
                >
                  <v-select
                    v-model="selectedAttributeOptions[selectedAttributes[i].id]"
                    :items="selectedAttributes[i].options"
                    item-text="name"
                    item-value="id"
                    v-validate.disable="'required'"
                    :error-messages="errors.collect(`option_${i}`)"
                    :data-vv-name="`option_${i}`"
                    :data-vv-as="$tc('option', 2)"
                    :label="$t('options-for-this-question')"
                    multiple
                    :menu-props="{ closeOnContentClick: true }"
                  >
                    <template slot="item" slot-scope="data">
                      <div class="slot-item">{{ data.item.name }}</div>
                    </template>
                  </v-select>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex v-if="selectedAttributes.length > 1">
              <v-btn icon class="mr-0 ml-4" @click.stop="removeAttribute(i)">
                <font-awesome-icon class="sw-accent" icon="trash" />
              </v-btn>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
      <v-flex v-if="filterAvalableAttributes()[0]" class="xs12 mb-4">
        <v-btn dark fab class="sw-accent-bg" @click="addAtribute">
          <v-icon dark>add</v-icon>
        </v-btn>
      </v-flex>
      <v-flex class="xs12 text-center">
        <v-btn
          v-if="
            attributeRelations &&
            attributeRelations.required_attributes &&
            attributeRelations.required_attributes[0]
          "
          round
          class="white sw-primary text-none"
          @click="deleteGroupUserAttributeRelations"
          >{{ $t("delete-relations") }}</v-btn
        >
        <v-btn
          round
          class="sw-secondary-bg sw-on-secondary text-none"
          @click="storeGroupUserAttributeRelations"
          >{{ $t("i-m-done") }}</v-btn
        >
        <v-btn
          round
          class="sw-secondary-bg sw-on-secondary text-none"
          @click="$emit('change-view-mode', 2)"
        >
          {{ $t("use-advanced-relations") }}
        </v-btn>
      </v-flex>
    </v-layout>
  </v-card-text>
</template>

<script>
import _ from "lodash";

export default {
  props: {
    groupId: {
      type: [String, Number],
      required: true,
    },
    groupPluginId: {
      type: [String, Number],
      required: true,
    },
    categoryId: {
      type: [String, Number],
      required: true,
    },
    attributeId: {
      type: [String, Number],
      required: true,
    },
  },
  data: () => ({
    loading: false,
    attribute: {},
    attributeRelations: {}, // Check if attribute has relations
    allAttributes: [],
    selectedAttributes: [],
    selectedAttributeOptions: {},
    requirements: {},
  }),
  computed: {
    selectedAttributeIds() {
      return this.selectedAttributes.map((attribute) =>
        attribute && attribute.id ? attribute.id : attribute,
      );
    },
    allAvailableAttributes() {
      return this.allAttributes.filter(
        ({ sort_order, enabled }) =>
          sort_order != this.attribute.sort_order &&
          sort_order < this.attribute.sort_order &&
          enabled,
      );
    },
    appContentLanguage() {
      return this.$store.getters.appContentLanguage;
    },
  },
  mounted() {
    this.loading = true;
    this.attribute = {};
    this.allAttributes = [];
    this.selectedAttributes = [];
    this.selectedAttributeOptions = {};
    this.requirements = {};

    this.getUserAttribute(this.attributeId);
    this.getUserAttributeList();
  },
  methods: {
    addAtribute() {
      this.selectedAttributes.push(null);
    },
    removeAttribute(index) {
      if (this.selectedAttributes[index]) {
        this.$delete(
          this.selectedAttributeOptions,
          this.selectedAttributes[index].id,
        );
      }

      this.$delete(this.selectedAttributes, index);
    },
    filterAvalableAttributes(attributeToInclude) {
      // Return all attributes that are not selected
      if (!attributeToInclude) {
        return this.allAvailableAttributes.filter(({ id }) => {
          if (this.selectedAttributeIds.includes(id)) {
            return;
          }
          return true;
        });
      }
      // Return all attributes that are not selected including given attribute
      return this.allAvailableAttributes.filter(({ id }) => {
        if (
          attributeToInclude.id !== id &&
          this.selectedAttributeIds.includes(id)
        ) {
          return;
        }
        return true;
      });
    },
    transformRequirementsForSave(selectedAttributeOptions) {
      for (const reqKey of Object.keys(this.requirements)) {
        let opts = {};

        for (const attrKey of Object.keys(selectedAttributeOptions)) {
          // Check if attribute for this option is selected
          if (
            this.selectedAttributeIds.includes(Number(attrKey)) &&
            selectedAttributeOptions[attrKey]
          ) {
            for (const opt of selectedAttributeOptions[attrKey]) {
              this.$set(opts, opt, Number(attrKey));
            }
          }
        }

        this.requirements[reqKey].opts = opts;
      }

      // Check if requirements not empty
      const key = Object.keys(this.requirements)[0];

      if (this.requirements[key] && !_.isEmpty(this.requirements[key].opts)) {
        return this.requirements;
      }
    },
    getUserAttribute(attributeId) {
      const params = [
        this.groupId,
        attributeId,
        { lang: this.appContentLanguage },
      ];

      this.$api.groupUserAttributes.get(...params).then((res) => {
        if (res.status === 200) {
          this.attribute = res.data.data;

          // Define requirements
          if (res.data.data.type === "options") {
            for (const option of res.data.data.options) {
              this.requirements[option.id] = { mode: "all", opts: {} };
            }
          } else {
            this.requirements[0] = { mode: "all", opts: {} };
          }

          this.getGroupUserAttributeRelations(res.data.data.id);
        }
      });
    },
    getUserAttributeList() {
      const params = [
        this.groupId,
        {
          group_plugin_id: this.groupPluginId,
          categories: this.categoryId,
          types: "options",
          lang: this.appContentLanguage,
          per_page: 100,
        },
      ];

      this.$api.groupUserAttributes.list(...params).then((response) => {
        if (response.status === 200 && response.data.data) {
          this.allAttributes = response.data.data;
        }
      });
    },
    getGroupUserAttributeRelations(attributeId) {
      if (typeof attributeId === undefined) return;

      const params = [this.groupId, attributeId];

      this.$api.groupUserAttributeRelations.get(...params).then((response) => {
        if (response.status === 200 && response.data.data) {
          this.loading = false;

          this.attributeRelations = response.data.data;

          // Add empty attribute if no relations created
          if (_.isEmpty(response.data.data.required_attributes)) {
            return this.addAtribute();
          }

          for (const reqAttr of response.data.data.required_attributes) {
            // Add required attributes
            this.selectedAttributes.push(reqAttr);
            // Define required attributes
            this.$set(this.selectedAttributeOptions, reqAttr.id, []);
          }

          const requirements = response.data.data.requirements;

          if (!_.isEmpty(requirements)) {
            const keys = Object.keys(requirements);
            // Get opts from requirements
            const opts = Object.entries(requirements[keys[0]].opts);
            // Add requirements
            for (const [key, value] of opts) {
              this.selectedAttributeOptions[value].push(Number(key));
            }
          }
        }
      });
    },
    async storeGroupUserAttributeRelations() {
      const valid = await this.$validator.validate();

      if (!valid) return;

      const model = {
        requirements: this.transformRequirementsForSave(
          this.selectedAttributeOptions,
        ),
      };

      if (_.isEmpty(model.requirements)) {
        return;
      }

      const params = [this.groupId, this.attribute.id, model];

      this.$api.groupUserAttributeRelations
        .store(...params)
        .then((response) => {
          if (response.status === 200) {
            this.$emit("update-relation", { params, data: response.data.data });
            this.$emit("close-dialog");
          }
        });
    },
    deleteGroupUserAttributeRelations() {
      const params = [this.groupId, this.attributeId];

      this.$api.groupUserAttributeRelations
        .delete(...params)
        .then((response) => {
          if (response.status === 200) {
            this.$emit("delete-relation", { params, data: response.data.data });
            this.$emit("close-dialog");
          }
        });
    },
  },
};
</script>

<style>
.overflow-hidden {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
