<template>
  <div class="advanced-relations">
    <v-card-text v-if="!loading" class="pt-0">
      <div class="mb-4 sw-h5 text-center">{{ attribute.name }}</div>
      <div class="content-scrollbar">
        <v-layout class="row nowrap mb-4">
          <v-flex xs2 class="pb-2 divider-border"></v-flex>
          <v-flex xs10>
            <v-layout class="row nowrap">
              <div
                v-for="(option, i) in attribute.options"
                :key="`option-${i}`"
                :style="rowStyle"
                class="text-center pb-2 divider-border"
              >
                <div class="">{{ option.name }}</div>
                <v-checkbox
                  v-if="requirements[option.id]"
                  v-model="requirements[option.id]['mode']"
                  true-value="all"
                  false-value="any"
                  hide-details
                  class="ma-0 justify-center"
                />
              </div>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-layout
          v-for="(selectedAttr, attrIndex) in selectedAttributes"
          :key="`selected-attr-${attrIndex}`"
          class="row wrap pb-4"
        >
          <v-flex class="xs12">
            <v-layout class="row nowrap mb-3 align-center">
              <v-flex class="xs12">
                <v-btn icon class="mx-0" @click="removeAttribute(attrIndex)">
                  <font-awesome-icon class="sw-accent" icon="trash" />
                </v-btn>
                <span class="font-weight-bold ml-2">{{ selectedAttr.name }}</span>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12>
            <v-layout class="row nowrap">
              <v-flex xs2>
                <v-layout class="row wrap">
                  <v-flex
                    v-for="(selectedAttrOpt, optIndex) in selectedAttr.options"
                    :key="`selected-attr-opt-${optIndex}`"
                    class="xs12 option-name"
                  >
                    {{ selectedAttrOpt.name }}
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs10>
                <v-layout class="row wrap">
                  <v-flex
                    v-for="(selectedAttrOpt, optIndex) in selectedAttr.options"
                    :key="`selected-attr-opt-${optIndex}`"
                    class="xs12"
                  >
                    <v-layout class="row nowrap align-center">
                      <v-flex
                        v-for="(option, i) in attribute.options"
                        :key="`option-${i}`"
                        :style="rowStyle"
                        class="option-select"
                      >
                        <v-checkbox
                          v-if="requirements[option.id]"
                          v-model="requirements[option.id]['opts'][selectedAttrOpt.id]"
                          :true-value="selectedAttr.id"
                          :false-value="null"
                          hide-details
                          class="ma-0 justify-center"
                        />
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </div>
      <v-divider class="my-2" />
      <v-layout class="row wrap">
        <v-flex v-if="customError" class="xs12">
          <span class="sw-accent">{{ customError }}</span>
        </v-flex>
        <v-flex class="xs12 mb-4">
          <v-autocomplete
            v-model="selectedAttributes"
            :items="allAvailableAttributes"
            item-text="name"
            item-value="id"
            :label="$t('search-for-question')"
            auto-select-first
            persistent-hint
            hide-selected
            return-object
            multiple
            :menu-props="{ closeOnContentClick: true }"
          >
            <template slot="no-data">
              <div class="px-4 py-3">{{ $t("no-data") }}</div>
            </template>
            <template slot="item" slot-scope="data">
              <div class="slot-item overflow-hidden">
                {{ data.item.name }}
                <div v-if="data.item.description" class="caption grey--text">
                  {{ data.item.description }}
                </div>
              </div>
            </template>
          </v-autocomplete>
        </v-flex>
      </v-layout>
      <div class="text-center">
        <v-btn
          v-if="attributeRelations.attribute_id"
          round
          class="white sw-primary text-none"
          @click="deleteGroupUserAttributeRelations"
          >{{ $t("delete-relations") }}</v-btn
        >
        <v-btn
          round
          class="sw-secondary-bg sw-on-secondary text-none"
          @click="storeGroupUserAttributeRelations"
          >{{ $t("save") }}</v-btn
        >
        <v-btn
          v-if="!attributeRelations.attribute_id"
          round
          class="sw-secondary-bg sw-on-secondary text-none"
          @click="$emit('change-view-mode', 1)"
          >{{ $t("use-simple-relations") }}</v-btn
        >
      </div>
    </v-card-text>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  props: {
    groupId: {
      type: [String, Number],
      required: true,
    },
    groupPluginId: {
      type: [String, Number],
      required: true,
    },
    categoryId: {
      type: [String, Number],
      required: true,
    },
    attributeId: {
      type: [String, Number],
      required: true,
    },
  },
  data: () => ({
    loading: false,
    attribute: {},
    allAttributes: [],
    attributeRelations: {},
    selectedAttributes: [],
    requirements: {},
    perPage: 100,
    timeout: null,
    customError: null,
  }),
  computed: {
    selectedAttributeIds() {
      return this.selectedAttributes.map(({ id }) => id);
    },
    allAvailableAttributes() {
      return this.allAttributes.filter(
        ({ sort_order, enabled }) =>
          sort_order != this.attribute.sort_order &&
          sort_order < this.attribute.sort_order &&
          enabled,
      );
    },
    rowStyle() {
      return {
        minWidth: '150px',
        maxWidth: '150px',
      };
    },
    appContentLanguage() {
      return this.$store.getters.appContentLanguage;
    },
  },
  watch: {
    selectedAttributeIds(newVal, oldVal) {
      this.clearAttributes();
    },
  },
  mounted() {
    this.allAttributes = [];
    this.attributeRelations = {};
    this.selectedAttributes = [];
    this.requirements = {};

    this.listGroupUserAttributes();
    this.getUserAttribute(this.attributeId);
  },
  methods: {
    transformRequirementsForSave(requirements) {
      if (typeof requirements == undefined) return;

      let obj = {};

      for (const req in requirements) {
        if (requirements.hasOwnProperty(req)) {
          this.$set(obj, req, {
            mode: requirements[req]["mode"],
            opts: _.pickBy(requirements[req]["opts"]),
          });

          if (_.isEmpty(obj[req]["opts"])) {
            delete obj[req];
          }
        }
      }

      return obj;
    },
    listGroupUserAttributes() {
      const params = [
        this.groupId,
        {
          types: "options",
          categories: this.categoryId,
          group_plugin_id: this.groupPluginId,
          lang: this.appContentLanguage,
          per_page: this.perPage,
        },
      ];

      this.$api.groupUserAttributes
        .list(...params)
        .then((response) => {
          this.allAttributes = response.data.data;
        })
        .catch(() => {});
    },
    getUserAttribute(attributeId) {
      this.loading = true;

      const params = [
        this.groupId,
        attributeId,
        { lang: this.appContentLanguage },
      ];

      this.$api.groupUserAttributes
        .get(...params)
        .then((response) => {
          if (response.data) {
            if (
              response.data.data.type == "options" &&
              response.data.data.options.length > 0
            ) {
              for (const option of response.data.data.options) {
                this.$set(this.requirements, option.id, {
                  mode: "any",
                  opts: {},
                });
              }
            } else {
              this.$set(this.requirements, 0, {
                mode: "any",
                opts: {},
              });
            }

            this.attribute = response.data.data;

            if (response.data.data.type != "options") {
              this.attribute.options = [
                {
                  id: 0,
                  name: this.$t("default"),
                },
              ];
            }

            this.getGroupUserAttributeRelations(response.data.data.id);
          }
        })
        .catch(() => {});
    },
    getGroupUserAttributeRelations(attributeId) {
      if (typeof attributeId == undefined) return;

      const params = [this.groupId, attributeId];

      this.$api.groupUserAttributeRelations.get(...params).then((response) => {
        if (response.status === 200 && response.data.data) {
          this.loading = false;

          this.attributeRelations = response.data.data;

          if (response.data.data.required_attributes) {
            for (const el of response.data.data.required_attributes) {
              this.addNewAtribute(el);
            }

            for (const req in response.data.data.requirements) {
              let newReq = {};
              Object.entries(
                response.data.data.requirements[req]["opts"],
              ).forEach(([key, value]) => (newReq[key] = Number(value)));
              response.data.data.requirements[req]["opts"] = newReq;
            }

            this.transformRequirementsForEdit(response.data.data.requirements);
          }
        }
      });
    },
    transformRequirementsForEdit(reqs) {
      for (let key in this.requirements) {
        if (this.requirements.hasOwnProperty(key) && reqs[key]) {
          this.requirements[key] = reqs[key];
        }
      }
    },
    async storeGroupUserAttributeRelations() {
      const model = {
        requirements: this.transformRequirementsForSave(this.requirements),
      };

      if (_.isEmpty(model.requirements)) {
        this.customError = "Please select answers";
        return;
      }

      const params = [this.groupId, this.attribute.id, model];

      this.$api.groupUserAttributeRelations
        .store(...params)
        .then((response) => {
          if (response.data.status == "ok") {
            this.$emit("update-relation", { params, data: response.data.data });
            this.$emit("close-dialog");
          }
        });
    },
    deleteGroupUserAttributeRelations() {
      const params = [this.groupId, this.attribute.id];

      this.$api.groupUserAttributeRelations
        .delete(...params)
        .then((response) => {
          if (response.data.status == "ok") {
            this.$emit("delete-relation", { params, data: response.data.data });
            this.$emit("close-dialog");
          }
        });
    },
    addNewAtribute(attribute) {
      if (!attribute) return;

      const el = this.selectedAttributes.find(({ id }) => id == attribute.id);

      if (!el) {
        this.selectedAttributes.push(attribute);
      }
    },
    removeAttribute(index) {
      if (typeof index == undefined) return;

      this.$delete(this.selectedAttributes, index);
    },
    clearAttributes() {
      for (const req in this.requirements) {
        if (this.requirements.hasOwnProperty(req)) {
          Object.entries(this.requirements[req]["opts"]).map(([key, value]) => {
            if (!this.selectedAttributeIds.includes(value)) {
              delete this.requirements[req]["opts"][key];
            }
          });
        }
      }
    },
  },
};
</script>

<style lang="scss">
.advanced-relations {
  .v-ripple__container {
    display:none !important;
  }

  .v-input--selection-controls {
    padding: 0 !important;

    .v-input--selection-controls__input {
      margin: 0 !important;
    }
  }

  .option-name, .option-select {
    display: flex;
    align-items: center;
    height: 28px;
  }

  .content-scrollbar{
    overflow: auto;
    max-height: 300px;
  }

  .divider-border {
    border-bottom: 1px solid rgba(0,0,0,0.12);
  }
}
</style>
