<template>
  <v-dialog v-model="dialog" width="600" persistent>
    <v-card class="border-radius">
      <v-card-actions
        ><v-spacer></v-spacer
        ><v-btn icon @click="closeDialog">
          <v-icon small>close</v-icon></v-btn
        ></v-card-actions
      >
      <v-card-text class="pt-0">
        <div class="mb-5 title font-weight-medium text-center">
          {{ $t("signup_wizard.tag_delete_confirmation_dialog_title") }}
        </div>
        <v-text-field
          v-model="deleteConfirmationText"
          :placeholder="$t('signup_wizard.tag_delete_confirmation_placeholder')"
        ></v-text-field>
        <div class="text-center">
          <v-btn round class="text-none" @click="closeDialog">{{
            $t("common.cancel")
          }}</v-btn>
          <v-btn
            round
            class="red white--text text-none"
            :disabled="!canConfirm || loading"
            @click="confirm"
            >{{ $t("common.confirm") }}</v-btn
          >
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "AttributeTagDeleteConfirmationDialog",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    dialog: false,
    deleteConfirmationText: "",
  }),
  computed: {
    canConfirm() {
      return (
        this.deleteConfirmationText ===
        this.$t("signup_wizard.tag_delete_confirmation_text")
      );
    },
  },
  methods: {
    openDialog() {
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
      this.deleteConfirmationText = "";
    },
    confirm() {
      this.$emit("confirm");
      this.closeDialog();
    },
  },
};
</script>

<style lang="scss" scoped></style>
